import React, { useEffect } from 'react';
import * as styles from './Feature.module.scss';

export type Feature = {
  Title: string;
  Description: string;
  Image: string;
}

interface Props {
  feature: Feature;
}

const Feature: React.FC<Props> = ({ feature }) => {
  useEffect(() => {
    console.log(feature);
  }, [feature])

  return (<>
    {feature ? <div className={styles.Packages}>
      <div className={styles.Package}>
        <div className={styles.PackageInfo}>
          <div className={styles.PackageInfoInner}>
            <h1>{feature.Title}</h1>
            <div dangerouslySetInnerHTML={{ __html: feature.Description }}></div>
          </div>
        </div>
      </div>
    </div> : <></>}
  </>);
};

export default Feature;
