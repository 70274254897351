// extracted by mini-css-extract-plugin
export var Active = "Feature-module--Active--ea8c8";
export var Button = "Feature-module--Button--3ad72";
export var Images = "Feature-module--Images--a7882";
export var Loading = "Feature-module--Loading--21d8c";
export var Message = "Feature-module--Message--ed1b0";
export var MoreFrom = "Feature-module--MoreFrom--107fb";
export var MoreFromInner = "Feature-module--MoreFromInner--7f837";
export var Package = "Feature-module--Package--8a59f";
export var PackageDoc = "Feature-module--PackageDoc--4c684";
export var PackageInfo = "Feature-module--PackageInfo--64411";
export var PackageInfoInner = "Feature-module--PackageInfoInner--25414";
export var PackageInner = "Feature-module--PackageInner--12b8a";
export var Packages = "Feature-module--Packages--7c835";
export var Parameters = "Feature-module--Parameters--21cac";
export var Rating = "Feature-module--Rating--9b6cb";
export var RatingInner = "Feature-module--RatingInner--ec0b9";
export var RatingPerc = "Feature-module--RatingPerc--e2c11";
export var RatingStars = "Feature-module--RatingStars--57d46";
export var Ratings = "Feature-module--Ratings--b9a9f";
export var RatingsForm = "Feature-module--RatingsForm--c9378";
export var RatingsFormStars = "Feature-module--RatingsFormStars--d223b";
export var RatingsList = "Feature-module--RatingsList--0b8a0";
export var RatingsListButton = "Feature-module--RatingsListButton--cd404";
export var RatingsListCarousel = "Feature-module--RatingsListCarousel--c5374";
export var RatingsListCarouselInner = "Feature-module--RatingsListCarouselInner--e1210";
export var RatingsListItem = "Feature-module--RatingsListItem--e90d0";
export var RatingsOverall = "Feature-module--RatingsOverall--16c01";
export var RatingsOverallItem = "Feature-module--RatingsOverallItem--bc44d";
export var RatingsOverallItemBar = "Feature-module--RatingsOverallItemBar--96f78";
export var RatingsOverallItemBarInner = "Feature-module--RatingsOverallItemBarInner--42243";
export var Right = "Feature-module--Right--343ae";
export var Share = "Feature-module--Share--ab69e";
export var Side = "Feature-module--Side--68775";
export var SideInfo = "Feature-module--SideInfo--6bf31";
export var Social = "Feature-module--Social--e1e73";