import React, { FC } from "react";
import Layout from "../components/Layout/layout";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner/Banner";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { StopWords } from "../Data/StopWords";
import Feature, { Feature as Props } from "../Views/Website/Feature/Feature";

const FeaturePage: FC = (props: any) => {
    const feature: Props = props.pageContext.Feature;

    const GetKeyWords = () => {
        var x, y, word, stop_word, regex_str, regex;
        let cleansed_string = feature.Description;
        let stop_words = StopWords;
        let words = cleansed_string.match(/[^\s]+|\s+[^\s+]$/g)

        if (words)
            for (x = 0; x < words.length; x++) {
                for (y = 0; y < stop_words.length; y++) {
                    word = words[x].replace(/\s+|[^a-z]+/ig, "");
                    stop_word = stop_words[y];

                    if (word.toLowerCase() == stop_word) {
                        regex_str = "^\\s*" + stop_word + "\\s*$";
                        regex_str += "|^\\s*" + stop_word + "\\s+";
                        regex_str += "|\\s+" + stop_word + "\\s*$";
                        regex_str += "|\\s+" + stop_word + "\\s+";
                        regex = new RegExp(regex_str, "ig");
                        cleansed_string = cleansed_string.replace(regex, " ");
                    }
                }
            }
        return cleansed_string.replace(/^\s+|\s+$/g, "");
    }

    return (
        <Layout>
            {feature ? <>
                <Helmet htmlAttributes={{ lang: 'en' }}>
                    <title>PrimeHR :: {feature.Title}</title><meta charSet='utf-8' />
                    <meta property="og:description" content={`${feature.Description}`} />
                    <meta property="og:title" content={`PrimeHR :: ${feature.Title}`} />
                    <meta property="og:site_name" content="PrimeHR" />
                    <meta property="og:url" content={`${props.location.href}`} />
                    <meta name="keywords" content={GetKeyWords()}></meta>
                </Helmet>

                <Banner />
                <Breadcrumb Trail={[
                    { To: `/Packages/Compare`, Text: "Compare Packages" },
                    { To: ``, Text: feature.Title }
                ]} />
                <Feature feature={feature} />
            </> : <></>}
        </Layout>
    );
}

export default FeaturePage;